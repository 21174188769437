import React, { useEffect, useState } from "react";
import styles from "../Settings.module.css";
import axios from "axios";
function MotionDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState(null);

  const [sensitivityLevel, setSensitivityLevel] = useState("");
  const [isAlarmSoundEnabled, setIsAlarmSoundEnabled] = useState(false);
  const [isWhiteLightEnabled, setIsWhiteLightEnabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getmotion`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );
        setIsEnabled(response.data.enabled);
        setSensitivityLevel(response.data.detectionGrid.sensitivityLevel);
      } catch (error) {
        setError(error);
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/motion-alarm`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsAlarmSoundEnabled(response.data.AlarmSound);
        setIsWhiteLightEnabled(response.data.AlarmWhiteLight);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  const updateMotion = async () => {
    const parsedSensitivity = parseInt(sensitivityLevel, 10);

    // Validate sensitivity level
    if (
      isNaN(parsedSensitivity) ||
      parsedSensitivity < 1 ||
      parsedSensitivity > 100
    ) {
      setError(new Error("Sensitivity level must be between 1 and 100."));
      return;
    }

    const settings = {
      enabled: isEnabled,
      sensitivityLevel: parsedSensitivity,
    };

    try {
      const responseSettings = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/motionDetection-settings`,
        settings,
        {
          params: {
            deviceId: deviceId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Motion Detection Settings Response:", responseSettings.data);

      const alarm = {
        AlarmSound: isAlarmSoundEnabled,
        AlarmWhiteLight: isWhiteLightEnabled,
      };

      const responseAlarm = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/set-motion-alarm`,
        alarm,
        {
          params: {
            deviceId: deviceId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Motion Alarm Settings Response:", responseAlarm.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      setError(error);
    }
  };

  return (
    <div className={styles.notify}>
      <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "8px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}> Motion Detection</label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enabled" style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>Enabled</label>
        <input
          type="checkbox"
          id="enabled"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          onChange={(e) => setIsEnabled(e.target.checked)}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label htmlFor="sensitivity" style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>Sensitivity Level</label>
        <input
          type="text"
          id="sensitivity"
          onChange={(e) => setSensitivityLevel(e.target.value)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        
        />
        <span>(0-100)</span>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label htmlFor="alarmSound" style={{ fontWeight: "bold",marginRight:'16px' }}>
          Enable Alarm Sound
        </label>
        <input
          type="checkbox"
          id="alarmSound"
          onChange={(e) => setIsAlarmSoundEnabled(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight" style={ {fontWeight: "bold",marginRight:'2.5%' }}>
          Enable White Light
        </label>
        <input
          type="checkbox"
          id="whiteLight"
          onChange={(e) => setIsWhiteLightEnabled(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div >
        <button onClick={updateMotion} style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "10px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>Save</button>
      </div>
    </div>
  );
}

export default MotionDetection;
