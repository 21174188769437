import axios from "axios";

export async function getSDCardStatus() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/p2p/tfdata`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: "Basic YWRtaW46", // Ensure this is correct and properly encoded
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSDCardData(currentPage, deviceId,selectedDate) {
  console.log(deviceId);
  try {

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/p2p/tfdata`, {
        deviceId,
        currentPage,
        formattedDate:selectedDate,
    });

    const data = await response.data;
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getSDCardDataDownload(currentPage) {
  try {
    const requestBody = {
      DEV: "IPC",
      VER: "1.0",
      API: "R.SearchRecord",
      Parameter: {
        Type: ["False", "False"],
        Date: "2024-07-09",
        BeginTime: "00:00:00",
        EndTime: "23:59:59",
        PageSize: 10,
        CurrentPage: currentPage,
        Reload: "True",
        TypeFlags2: "0x7E033",
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/p2p/tfdata`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: "Basic YWRtaW46", // Ensure this is correct and properly encoded
        },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
}
